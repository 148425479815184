<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{ $t("WAREHOUSES.ADD_WAREHOUSE") }}</h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <warehouse-form
              :loading="loading"
              :warehouseData="warehouse"
              :formErrors="formErrors"
              @warehouseSubmitted="handleSubmit"
              @formChanged="() => (alertLeave = true)"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultWarehouse from "./defaultWarehouse";
import WarehouseForm from "./partials/WarehouseForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    WarehouseForm,
  },

  mixins: [alertLeave],

  data() {
    const warehouse = cloneDeep(defaultWarehouse);
    return {
      warehouse: warehouse,
      formErrors: null,
      loading: false,
    };
  },

  methods: {
    goBack() {
      this.$router.push({ name: "List Warehouses" });
    },

    viewWarehouse(warehouse) {
      this.alertLeave = false;
      this.$router.push({
        name: "View Warehouse",
        params: { id: warehouse.id },
      });
    },

    async handleSubmit(warehouse) {
      this.loading = true;
      const warehouseData = cloneDeep(warehouse);

      try {
        await this.$store.dispatch("warehouses/add", warehouseData);
        this.$notify({
          type: "success",
          message: this.$t("WAREHOUSES.WAREHOUSE_ADDED"),
        });
        const warehouse = await this.$store.getters["warehouses/warehouse"];
        this.viewWarehouse(warehouse);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
